<template lang="pug">
.profile-discover
  .profile-discover__brand
    FcwRouterLink(
      :location="homepage"
      hide-arrow
    ) 
      FcwClientLogo
    FcwChip(
      v-if="isPreview"
      :label="t('common__preview_mode')"
    )
  NuxtPage
  Teleport(to="body")
    FcwAppStoresFallback.profile-discover__mobileFallback(v-if="isSmAndDown")
</template>

<style lang="stylus">
.profile-discover
  z-index 0
  overflow hidden

.profile-discover__brand
  position fixed
  left rem(24)
  top rem(24)
  color var(--color--primary)
  z-index 1
  display flex
  align-items center
  gap rem(16)

.profile-discover__mobileFallback
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  z-index 10

  .FcwAppStoresFallback__mainContent
    margin-top rem(32)
</style>

<script setup lang="ts">
const { isSmAndDown } = useFBreakpoints();
const { homepage } = useClientStore();
const { isPreview } = useGlobalStore();
const { t } = useClientI18n();
</script>
